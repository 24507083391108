import { graphql, useStaticQuery } from 'gatsby';
import { IAboutResponse } from 'interfaces/interfaces';

const useAbout = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: {
          // todo: for everyone interface
          title,
          description,
        },
      },
    },
  } = useStaticQuery<IAboutResponse>(graphql`
    query about {
      markdownRemark(frontmatter: { type: { eq: "about" } }) {
        frontmatter {
          english {
            title
            description
          }
        }
      }
    }
  `);

  return { title, description };
};

export default useAbout;
