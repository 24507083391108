import HiTechSolution from 'assets/images/ui-elements/cards/hi-tech-solution.svg';
import ElectricCar from 'assets/images/ui-elements/cards/electric-cars.svg';
import OneAppFitsAll from 'assets/images/ui-elements/cards/one-app-fits-all.svg';
import BeneficialPartnership from 'assets/images/ui-elements/cards/beneficial-partnership.svg';
import WhyChooseUs from 'assets/images/ui-elements/cards/why-choose-us.svg';

export type approachesTypes =
  | 'hi-tech-solution'
  | 'electric-cars'
  | 'one-app-fits-all'
  | 'beneficial-partnership'
  | 'why-choose-us';

export const APPROACHES = {
  'hi-tech-solution': HiTechSolution,
  'electric-cars': ElectricCar,
  'one-app-fits-all': OneAppFitsAll,
  'beneficial-partnership': BeneficialPartnership,
  'why-choose-us': WhyChooseUs,
};
