import Section from 'components/section/components/Section';
import useAbout from './useAbout';
import { SectionDescription } from 'components/section/styles/styled';
import { useTranslation } from 'react-i18next';

const AboutTemplate = () => {
  const { title, description } = useAbout();
  const { t } = useTranslation();

  return (
    <Section title={t(title)}>
      <SectionDescription>{t(description)}</SectionDescription>
    </Section>
  );
};

export default AboutTemplate;
