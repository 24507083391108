import { graphql, useStaticQuery } from 'gatsby';
import { IWeHelpBigCitiesResponse } from 'interfaces/interfaces';

const useWeHelpBigCities = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, paragraph1, paragraph2, paragraph3 },
      },
    },
  } = useStaticQuery<IWeHelpBigCitiesResponse>(graphql`
    query help {
      markdownRemark(frontmatter: { type: { eq: "help" } }) {
        frontmatter {
          english {
            title
            paragraph1
            paragraph2
            paragraph3 {
              description
              list {
                listItem
              }
            }
          }
        }
      }
    }
  `);

  return { title, paragraph1, paragraph2, paragraph3 };
};

export default useWeHelpBigCities;
