import { graphql, useStaticQuery } from 'gatsby';
import { ISmartApproachResponse } from 'interfaces/interfaces';

const useSmartApproach = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, approaches },
      },
    },
  } = useStaticQuery<ISmartApproachResponse>(graphql`
    query approach {
      markdownRemark(frontmatter: { type: { eq: "approach" } }) {
        frontmatter {
          english {
            title
            approaches {
              id
              icon
              title
              description
              link
            }
          }
        }
      }
    }
  `);

  return { title, approaches };
};

export default useSmartApproach;
