import styled from 'styled-components';
import { IOpenCard } from 'components/card-info/interfaces/interfaces';
import ArrowCard from 'assets/images/ui-elements/arrows/arrow-card.svg';
import { DEFAULT_HEIGHT_CONTAINER } from 'components/card-info/components/CardInfo';
import { COLORS } from 'globalStyles/colors';
import {
  DESKTOP,
  MOBILE_375,
  MOBILE_425,
  TABLET_1024,
  TABLET_768,
  XS_MAX,
} from '../../../constants/breakpoints';

const CardInfoContainer = styled.a<Partial<IOpenCard>>`
  // isLink //
  background-color: ${({ isLink }) =>
    isLink ? COLORS.white : COLORS.tertiaryGrey};
  ${({ isLink }) => isLink && 'cursor: pointer'};
  :hover {
    ${({ isLink }) => isLink && 'transform: scale(1.059)'};
  }
  :active {
    ${({ isLink }) => isLink && `background: ${COLORS.tertiaryGrey}`};
  }
  // isLink //
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`};
  height: ${({ height, isOpen }) => (!isOpen ? `${height}px` : '100%')};
  width: 40rem;
  border-radius: 0.8rem;
  padding: 2.4rem;
  box-shadow: 0 0.4rem 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: all 0.3s linear;
  margin-bottom: 2rem;
  justify-content: space-between;
  cursor: pointer;
  @media screen and (min-width: ${DESKTOP}) {
    flex: 0 0 calc(33.333% - 3rem);
  }
  @media screen and (max-width: ${TABLET_1024}) {
    flex: 0 0 49%;
  }
  @media screen and (max-width: ${TABLET_768}) {
    flex: 0 0 100%;
  }
  @media screen and (max-width: ${MOBILE_425}) {
    flex: 0 0 100%;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    flex: 0 0 100%;
  }
  @media screen and (max-width: ${XS_MAX}) {
    // todo: ??
    flex: 0 0 100%;
  }
`;

const CardInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardInfoTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardInfoArrowOpen = styled(ArrowCard)<Partial<IOpenCard>>`
  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'};
  cursor: pointer;
  transition: all 0.3s linear;
`;

const CardInfoTitle = styled.p`
  font: 2.4rem 'Avenir Heavy', sans-serif;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  user-select: none;
  @media screen and (max-width: ${MOBILE_425}) {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
  }
`;

const CardInfoDescription = styled.p<Partial<IOpenCard>>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : '-webkit-box')};
  padding-top: 1.6rem;
  font: normal 2rem 'Avenir', sans-serif;
  line-height: 2.7rem;
  ${({ height }) =>
    height === DEFAULT_HEIGHT_CONTAINER && '-webkit-line-clamp: 2'};
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: none;
  @media screen and (max-width: ${MOBILE_425}) {
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    padding-top: 1rem;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    padding-top: 1rem;
  }
`;

export {
  CardInfoContainer,
  CardInfoHeader,
  CardInfoTitleContainer,
  CardInfoTitle,
  CardInfoDescription,
  CardInfoArrowOpen,
};
