import Section from 'components/section/components/Section';
import useWeHelpBigCities from './useWeHelpBigCities';
import { SectionDescription } from 'components/section/styles/styled';
import { useTranslation } from 'react-i18next';

const WeHelpBigCities = () => {
  const { title, paragraph1, paragraph2, paragraph3 } = useWeHelpBigCities();
  const { t } = useTranslation();

  return (
    <Section title={t(title)}>
      <SectionDescription>{t(paragraph1)}</SectionDescription>
      <SectionDescription>{t(paragraph2)}</SectionDescription>
      <SectionDescription>{t(paragraph3?.description)}</SectionDescription>
      <ul>
        {paragraph3?.list?.map(({ listItem }) => (
          <SectionDescription key={listItem}>
            • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t(listItem)}
          </SectionDescription>
        ))}
      </ul>
    </Section>
  );
};

export default WeHelpBigCities;
