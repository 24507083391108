import { FC, useCallback, useState } from 'react';
import { ICardInfo } from 'components/card-info/interfaces/interfaces';
import * as S from 'components/card-info/styles/styled';

export const DEFAULT_HEIGHT_CONTAINER = 166;
const DEFAULT_MARGIN_RIGHT = 0;

const CardInfo: FC<ICardInfo> = ({
  title,
  description,
  icon: Icon,
  withOpen = true,
  height = DEFAULT_HEIGHT_CONTAINER,
  isLink = false,
  link,
  marginRight = DEFAULT_MARGIN_RIGHT,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const onToggle = useCallback(() => {
    if (withOpen) setOpen(!isOpen);
  }, [isOpen, withOpen]);

  return (
    <S.CardInfoContainer
      isOpen={isOpen}
      height={height}
      isLink={isLink}
      href={link}
      onClick={onToggle}
      marginRight={marginRight}
      target="_blank"
    >
      <S.CardInfoHeader>
        <S.CardInfoTitleContainer>
          <Icon />
          <S.CardInfoTitle>{title}</S.CardInfoTitle>
        </S.CardInfoTitleContainer>

        {withOpen && <S.CardInfoArrowOpen onClick={onToggle} $isOpen={isOpen} />}
      </S.CardInfoHeader>

      <S.CardInfoDescription isOpen={isOpen} height={height}>
        {description}
      </S.CardInfoDescription>
    </S.CardInfoContainer>
  );
};

export default CardInfo;
