import Section from 'components/section/components/Section';
import CardInfo from 'components/card-info/components/CardInfo';
import useSmartApproach from './useSmartApproach';
import { APPROACHES, approachesTypes } from '../../constants/approaches';
import { useTranslation } from 'react-i18next';

const MARGIN_RIGHT = 24;

const SmartApproach = () => {
  const { title, approaches } = useSmartApproach();
  const { t } = useTranslation();

  return (
    <Section title={t(title)} styles={{ justifyContent: 'flex-start' }}>
      {approaches?.map(({ id, title, icon, description, link }) => (
        <CardInfo
          key={id}
          title={t(title)}
          description={t(description)}
          icon={APPROACHES[icon as approachesTypes]}
          withOpen={!link}
          isLink={!!link}
          link={link}
          marginRight={MARGIN_RIGHT}
        />
      ))}
    </Section>
  );
};

export default SmartApproach;
